<template>
    <a href="#"
       @click.prevent="$root.$children[0].openModal('customer-modal', {customerId:row.item.id}, refreshTable)">
        {{ row.item.api_customer_id }}
    </a>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>